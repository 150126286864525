import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../state/authentication/actions";
import WebFrontNavlessLayout from "../../page/webfront/WebFrontNavlessLayout";
import Parametername from "../naming/Parametername";
import Pathname from "../naming/Pathname";

class AgreementPage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    this.handleAgreeButtonClick = this.handleAgreeButtonClick.bind(this);
    this.handleBackLinkClick = this.handleBackLinkClick.bind(this);
  }

  state = {
    isAgreeButton: false,
  };

  initializeFields(props) {
    const { search } = props.location;
    const searchParams = new URLSearchParams(search);
    this.pathname =
      searchParams.get(Parametername.pathname) || Pathname.webfront;
  }

  handleAgreeButtonClick() {
    const { actions } = this.props;
    this.setState({ isAgreeButton: true });

    actions.agree();
  }

  handleBackLinkClick() {
    const { actions } = this.props;
    actions.goto(this.pathname);
  }

  render() {
    return (
      <WebFrontNavlessLayout>
        <div className="agreement">
          {this.renderMessage()}
          {this.renderContents()}
          {this.renderSign()}
          {this.renderButton()}
        </div>
      </WebFrontNavlessLayout>
    );
  }

  renderMessage() {
    const { needToAgree } = this.props;
    return needToAgree ? (
      <div className="text">
        <p className="kakunin">利用規約同意確認</p>
        <p className="setsumei">
          「ミルモネット」をご利用いただくには、利用規約への同意が必要です。
          <br />
          なお、利用規約の内容は、サイト下部のリンクから、いつでもご確認いただけます。
        </p>
      </div>
    ) : (
      this.renderNavigation()
    );
  }

  renderNavigation() {
    return (
      <div className="navigation">
        <ul>
          <li>
            <a onClick={this.handleBackLinkClick}>前のページに戻る</a>
          </li>
        </ul>
      </div>
    );
  }

  renderContents() {
    return (
      <div>
        <h1>ミルモネット利用規約</h1>
        <div className="text">
          <p>
            本ミルモネット利用規約（以下「本利用規約」という）は、株式会社ウェルモ（以下「当社」という）が提供する「ミルモネット」の情報サービス及び関連する一切のサービス（理由の如何を問わず、名称または内容が変更された場合には、当該変更後のサービスを含む。以下、総称して「本サービス」という）に適用されるものとします。
          </p>
        </div>
        <h2>第1条 （定義）</h2>
        <div className="text">
          <p className="teigi">
            本利用規約において使用する用語の定義は、以下のとおりとします。
          </p>

          <dl>
            <dt>「本サービス」</dt>
            <dd>
              「ミルモネット(milmonet.jp)」を通じて提供される情報サービス（理由の如何を問わずウェブサイトの名称、ドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含む）の総称です。
            </dd>
            <dt>「会員」</dt>
            <dd>
              当社に対して本サービスを利用する申込みをされた自治体、法人、団体、個人をいいます。
            </dd>
            <dt>「利用サービス」</dt>
            <dd>会員が「本サービス」において利用する各サービスをいいます。</dd>
          </dl>
        </div>

        <h2>第2条（登録）</h2>
        <div className="text">
          <ol>
            <li>
              本サービスの利用を希望する者（以下「登録希望者」という）は、本利用規約を遵守することに同意し、かつ当社の定める登録情報（第12条第1項において定義）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの会員の登録を申請することができます。
            </li>
            <li>
              登録の申請は必ず本サービスを利用する自治体、法人、団体または個人自身が行わなければならず、原則として代理人による登録申請は認められません。また、登録希望者は、登録の申請にあたり、真実、正確かつ最新の情報を当社に提供しなければなりません。
            </li>
            <li>
              当社は、第1項に基づき登録を申請した者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。
              <ol>
                <li>本利用規約に違反するおそれがあると当社が判断したとき</li>
                <li>
                  当社に提供された登録情報の全部または一部につき虚偽、誤記または記載漏れがあったとき
                </li>
                <li>過去に本サービスの利用の登録を取り消された者であるとき</li>
                <li>
                  未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人､保佐人または補助人の同意等を得ていなかったとき
                </li>
                <li>
                  暴力団員等（第16条第1項aにおいて定義）である、または資金提供その他を通じて暴力団員等の維持、運営もしくは経営に協力もしくは関与する等暴力団員等との何らかの交流もしくは関与を行っていると当社が判断したとき
                </li>
                <li>その他、当社が登録を適当でないと判断したとき</li>
              </ol>
            </li>
            <li>
              当社は、前項その他当社の基準に従って、登録希望者の登録の可否を判断し、当社が登録を認める場合にはその旨を登録希望者に通知します。かかる通知により登録希望者の会員としての登録は完了し、会員が本サービスに初回ログインする事によって本利用規約の諸規定に従った本サービスの利用にかかる契約（以下「本契約」という）が会員と当社の間に成立します。
            </li>
            <li>
              会員は、登録情報に変更があった場合は、遅滞なく、当社の定める方法により、当該変更事項を当社に通知し、当社から要求された資料を提出するものとします。
            </li>
          </ol>
        </div>

        <h2>第3条（ユーザ名およびパスワードの管理）</h2>
        <div className="text">
          <ol>
            <li>
              会員は、当社が会員に付与または会員自らが付与する全てのユーザ名およびパスワードの管理責任を負うものとします。
            </li>
            <li>
              会員は、ユーザ名およびパスワードを第三者に利用させる行為、貸与、譲渡、名義変更、売買、質入れ等をしてはならないものとします。
            </li>
            <li>
              ユーザ名およびパスワードの管理不十分、使用上の過誤、第三者の使用等による損害の責任は会員が負うものとし、当社は一切責任を負わないものとします。
            </li>
            <li>
              会員は、ユーザ名およびパスワードの盗難があった場合、失念があった場合、または第三者に使用されていることが判明した場合には、ただちに当社にその旨を連絡するとともに、当社からの指示に従うものとします。
            </li>
            <li>
              前各項の規定にかかわらず、当社所定の手続に従い、会員は第三者に対して、ユーザー名およびパスワードの代行管理を委託することができるものとします。また、当該委託を受けた第三者は代行管理するユーザー名およびパスワードを利用して本サービスに掲載する情報の管理(登録、更新、削除等)を行えるものとします。
            </li>
          </ol>
        </div>

        <h2>第4条（個人情報の保護）</h2>
        <div className="text">
          <p>
            個人情報の取り扱いについては、別途記載の「個人情報保護方針」のとおりとします。
          </p>
        </div>

        <h2>第5条（サービスの提供）</h2>
        <div className="text">
          <ol>
            <li>
              当社は、会員に対し、本サービスへのアクセス権限として、ユーザ名とパスワードを付与するものとします。
            </li>
            <li>
              情報提供管理機能を利用出来る会員は、会員の事業所、従事者に対し、本サービスへのアクセス権限として、当社が付与するものとは別に、所定数のユーザ名とパスワードを付与することができます。
            </li>
            <li>
              会員は、方法の如何を問わず、本サービスのコピー、又は目的外使用をしないものとします。
            </li>
            <li>
              当社が提供する本サービスの内容は、当社がその時点で合理的に提供可能なものに限定します。
            </li>
            <li>
              当社は、会員に対してコンピュータに関する一般的な操作方法等のサポート及び本サービス以外の質問応答等のサポートは一切行わないものとします。
            </li>
          </ol>
        </div>

        <h2>第6条（禁止行為）</h2>
        <div className="text">
          <p>
            会員は、本サービスを利用するにあたり、以下の行為をしてはならないものとします。
          </p>
          <ol>
            <li>虚偽の内容を入力する行為</li>
            <li>
              他の会員、第三者、または当社に迷惑、不利益、損害を与える行為および権利・利益を侵害する行為（かかる侵害を直接または間接に惹起する行為を含む）
            </li>
            <li>
              本サービスで得た情報を、本サービスの利用目的の範囲を超えて第三者に譲渡し、又は営利を目的とした情報提供活動に用いる行為
            </li>
            <li>
              コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為
            </li>
            <li>本サービスの運営の妨げとなる一切の行為</li>
            <li>公序良俗に反する行為</li>
            <li>法令に反する一切の行為</li>
            <li>その他、当社が不適切と判断する一切の行為</li>
          </ol>
        </div>

        <h2>第7条（通知）</h2>
        <div className="text">
          <p>
            本利用規約に定める会員への通知は、会員画面上の「お知らせ」や電子メール、電話、FAXなど、当社が適切と判断する手段で行うものとします。
          </p>
        </div>

        <h2>第8条（本サービスの変更・中止）</h2>
        <div className="text">
          <ol>
            <li>
              当社が必要と判断した場合には、当社は会員に本サービスの変更、追加および中止の1ヶ月前にその旨を告知することで、本サービスの内容の全部又は一部の変更、追加および中止することができるものとします。ただし、軽微なもの、会員に不利益でないもの、および緊急を要するものについては、当該事前の告知なく行うことができるものとします。
            </li>
            <li>
              当社は、本条に基づき当社が行った措置に基づき会員に生じた損害について一切の責任を負いません。
            </li>
          </ol>
        </div>

        <h2>第9条（本サービスの中断）</h2>
        <div className="text">
          <ol>
            <li>
              会員は次のいずれかに該当する場合には本サービスの提供が中断されることを、予め了承するものとします。
              <ol>
                <li>
                  本サービス提供用の設備およびソフトウェアの保守上または工事上やむをえない場合
                </li>
                <li>
                  天災地変、労働協議等の不可抗力により本サービスの提供ができない場合
                </li>
                <li>
                  その他当社がその運用上または技術上、本サービスの一時的な中断を必要とした場合
                </li>
              </ol>
            </li>
            <li>
              当社は、前項の規定により本サービスの提供を中断する場合、原則として本サービスの提供を中断する1ヶ月前にその旨会員に通知します。ただし、緊急を要しやむをえない場合は、この限りではありません。
            </li>
            <li>
              当社は、本条に基づき当社が行った措置に基づき会員に生じた損害について一切の責任を負いません。
            </li>
          </ol>
        </div>

        <h2>第10条（設備の負担等）</h2>
        <div className="text">
          <ol>
            <li>
              本サービスの提供を受けるために必要な、コンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備および維持は、会員の費用と責任において行うものとします。
            </li>
            <li>
              会員は自己の本サービスの利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセスおよび情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。
            </li>
          </ol>
        </div>

        <h2>第11条（利用規約の変更）</h2>
        <div className="text">
          <p>
            当社は、本利用規約（当社ウェブサイトに掲載する本サービスに関するルール、諸規定等を含む。以下本項において同じ）を変更できるものとします。当社は、本利用規約を変更した場合には、会員に当該変更内容を通知するものとし、当該変更内容の通知後、会員が本サービスを利用した場合または当社の定める期間内に退会等（第14条第1項において定義）の手続をとらなかった場合には、会員は、本利用規約の変更に同意したものとみなします。
          </p>
        </div>

        <h2>第12条（登録情報の取り扱い）</h2>
        <div className="text">
          <ol>
            <li>
              当社は、会員が本サービスの利用に基づき登録した会員情報や事業所情報等の情報（以下「登録情報」という）について、特定の個人を識別、登録できないよう加工、集計、および分析した統計データを作成し、何らの制限なく閲覧、利用等することができるものとします。
            </li>
            <li>
              会員が本サービスの会員資格を喪失した場合、または当社が必要と判断した場合、当社は、会員が登録した登録情報について変更または削除することができるものとします。なお、変更または削除により会員に生じた損害について、当社は一切責任を負いません。
            </li>
          </ol>
        </div>

        <h2>第13条（キャンペーン、アンケート等）</h2>
        <div className="text">
          <ol>
            <li>
              当社は会員に対して、本サービスの一環として、キャンペーンおよびアンケート等を随時実施することができるものとします。
            </li>
            <li>
              会員によるキャンペーンおよびアンケート等の回答内容について、会員は当社に対して、当該内容を日本の国内外で無償で非独占的に使用する（複製、公開、送信、頒布、譲渡、貸与、翻訳、翻案を含む）権利を許諾（サブライセンス権を含む）したものとみなします。また、会員は著作者人格権を行使しないものとします。
            </li>
            <li>
              キャンペーンおよびアンケート等を実施した場合、当社が別途指定した会員（以下「当選者」という）に対して、プレゼントを提供することがあります。
            </li>
            <li>
              キャンペーンまたはアンケート等に参加しようとする会員は当社に対して、当社の指定した方法によって、プレゼントを提供するために必要な情報を登録しなければならないことがあるものとし、会員はこれに同意するものとします。
            </li>
            <li>
              前項の登録がされない場合、または虚偽の情報を登録した場合、もしくはその可能性があると当社が判断した場合、当選者はプレゼント（当該事由に該当すると当社が判断した時点で受領していないプレゼントを含む。以下本項において同じ）を受け取る権利を喪失し、当社は当選者に対してプレゼントを提供する義務を負わないものとします。
            </li>
            <li>
              本契約の解除、退会等（第14条第1項において定義）その他の理由により当選者による本サービスの利用が終了した場合、当選者はプレゼント（当該終了時点で受領していないプレゼントを含む。以下本項において同じ）を受け取る権利を喪失し、当社は当選者に対してプレゼントを提供する義務を負わないものとします。
            </li>
            <li>
              当社は、プレゼントおよび前2項により当選者に発生した損害、不利益について、一切賠償する義務を負わないものとします。
            </li>
            <li>
              当選者は、プレゼントを受け取る権利について、譲渡、貸与、名義変更、担保供与し、その他第三者の利用に供する行為をすることはできないものとします。
            </li>
          </ol>
        </div>

        <h2>第14条（会員による解約）</h2>
        <div className="text">
          <ol>
            <li>
              会員は、当社が定める方法により退会、または利用サービスの一部の解約（以下総称して「退会等」という）申請を行います。当社は会員から申請を受けた当月末日をもって、退会等の処理を行います。
            </li>
            <li>
              会員が退会等の時までに登録した情報は、当社にて一定期間保存後、削除できるものとします。また、退会等の後は、退会等の時までに登録した情報に関する引渡しなどは請求できないものとし、当社も応じないものとします。
            </li>
            <li>
              退会後も、別途記載の「個人情報保護方針」「個人情報の取り扱いについて」に則り、当社のサービスのご案内や資料送付などをさせていただく場合がございます。
            </li>
          </ol>
        </div>

        <h2>第15条（当社による解約等）</h2>
        <div className="text">
          <ol>
            <li>
              会員が次のいずれかに該当した場合、当社は事前の通知や催告なしに、本サービスの一部もしくは全部の利用を停止、会員登録の取消し、または本契約を解除することができるものとします。
              <ol>
                <li>
                  申込時の当社への届出内容に虚偽の事項が記載されていたことが判明したとき
                </li>
                <li>
                  本利用規約に違反し、相当の期間を定めて催告しても改善されないとき
                </li>
                <li>当社に重大な損害または危害を及ぼしたとき</li>
                <li>監督官庁から指定申請等の取消、停止等の処分を受けたとき</li>
                <li>
                  破産手続開始、民事再生手続開始、会社更生手続開始、特別清算手続開始の申立てを受け、または自ら申し立てを行ったとき
                </li>
                <li>
                  死亡したときまたは後見開始、保佐開始もしくは補助開始の審判を受けたとき
                </li>
                <li>
                  解散したとき、清算開始となったとき、または事業の全部（実質的に全部の場合を含む）を第三者に譲渡したとき
                </li>
                <li>
                  12ヶ月以上本サービスの利用がなく、当社からの連絡に対して応答がないとき
                </li>
                <li>信頼関係を毀損する重大な事態が生じたとき</li>
                <li>その他、前各号に準じる事態が生じたとき</li>
                <li>
                  その他、当社が会員としての登録の継続を適当でないと判断したとき
                </li>
              </ol>
            </li>
            <li>
              本利用規約への違反、その他当社が不適切と判断した行為を行った場合または前項のいずれかの行為を会員が行ったことにより、当社が損害を被った場合、当社は会員に対し当該損害の賠償を請求することができるものとします。
            </li>
            <li>
              本条第1項の解除事由により当社が本契約を解除した場合には、相手方に損害が生じたとしても、これによる一切の損害賠償責任を負わないものとします。
            </li>
          </ol>
        </div>

        <h2>第16条（反社会的勢力との取引排除）</h2>
        <div className="text">
          <ol>
            <li>
              会員は、当社に対し、次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。
              <ol>
                <li>
                  自己または自己の代理人もしくは媒介をする者が、現在、暴力団、暴力団員、暴力団員でなくなってから５年を経過していない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等、その他これらに準ずる者（以下総称して「暴力団員等」という）ではないこと
                </li>
                <li>
                  暴力団員等が経営を支配している、もしくは経営に実質的に関与していると認められる関係を有すること
                </li>
                <li>
                  自己もしくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること
                </li>
                <li>
                  暴力団員等に対して資金等を提供し、または便宜を供与するなどの関与をしていると認められる関係を有すること
                </li>
                <li>
                  役員または経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること
                </li>
              </ol>
            </li>
            <li>
              会員は自己または第三者をして、当社または当社の関係者に対し、詐術、暴力的行為、脅迫的言辞を用いる行為、法的な責任を超えた不当な要求行為、当社の信用を毀損し、または業務を妨害する行為、その他これらに準ずる行為をしないことを確約するものとします。
            </li>
            <li>
              当社は、会員が本条第1項および第2項の確約に違反した場合、催告することなくただちに本契約を解除し、併せてこれにより被った損害の賠償を会員に請求することができるものとします。
            </li>
            <li>
              当社は、前項により本契約を解除した場合において、会員に損害が生じたとしても、これを一切賠償しないものとします。
            </li>
          </ol>
        </div>

        <h2>第17条（免責）</h2>
        <div className="text">
          <ol>
            <li>
              本サービスは現状有姿で提供されるものであり、当社は本サービスについて、特定の目的への適合性、商業的有用性、完全性、継続性等を含め、一切保証を致しません。
            </li>
            <li>
              本サービスまたは当社ウェブサイトに関連して会員と他の会員その他の第三者との間において生じた取引、連絡、紛争等については、会員の責任において処理および解決するものとし、当社はかかる事項について一切責任を負いません。
            </li>
            <li>
              当社ウェブサイトから他のウェブサイトへのリンクまたは他のウェブサイトから当社ウェブサイトへのリンクが提供されている場合でも、当社は、当社ウェブサイト以外のウェブサイトおよびそこから得られる情報に関して如何なる理由に基づいても一切の責任を負わないものとします。
            </li>
            <li>
              当社は、会員が本サービスを利用することにより得た情報等について何らかの保証をするものではありません。
            </li>
            <li>
              当社が別段の意思表示をした場合を除き、本サービスを通じて提供を受けることのできる当社以外の第三者のコンテンツ等は、全て当該第三者の責任において提供されるものであり、当社は一切責任を負いません。
            </li>
            <li>
              本サービスの利用または利用不能、停止、中断、欠陥等により会員が被った損害については、当社は何ら責任を負わないものとします。
            </li>
            <li>
              会員が本サービスを利用するために使用するインターネット接続設備、および接続回線に問題が生じたとしても、当社は一切の責任を負いません。
            </li>
            <li>
              本サービスは、当社が別途定める動作保証環境に適した形で提供されるものとします。なお、動作保証環境を満たしている場合であっても、本サービスを適切に利用できない場合があり得ますが、この場合、当社は、法令に別段の定めのある場合を除き、会員が受ける不利益の一切を補償するものではありません。
            </li>
          </ol>

          <p className="dousa">動作保証環境</p>

          <table>
            <tbody>
              <tr>
                <td>ブラウザ</td>
                <td>
                  Microsoft Edge、Google Chrome
                  <br />
                  (いずれも最新バージョンにアップデートされていること)
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h2>第18条（第三者への委託）</h2>
        <div className="text">
          <p>
            当社は、本サービスの提供に必要な業務を第三者に委託することができるものとします。
          </p>
        </div>

        <h2>第19条（権利の帰属）</h2>
        <div className="text">
          <p>
            本サービスにおいて当社が制作または提供するコンテンツ、画面デザインその他の著作物等に関する著作権その他の知的財産権については、当社または当社が定める法人もしくは個人に帰属しており、本利用規約に定める登録に基づく本サービスの利用許諾は、本利用規約において明示されているものを除き、当社ウェブサイトまたは本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の譲渡または使用許諾を意味するものではありません。
          </p>
        </div>

        <h2>第20条（有効期間）</h2>
        <div className="text">
          <p>
            本契約は、会員について第2条に基づく初回ログインを行った時点で効力を生じ、当該会員の登録が取り消された日または本サービスの提供が終了した日のいずれか早い日まで、当社と会員との間で有効に存続するものとします。
          </p>
        </div>

        <h2>第21条（事業譲渡等）</h2>
        <div className="text">
          <ol>
            <li>
              当社は、本サービスの営業を他社に譲渡（事業譲渡、会社分割その他態様の如何を問わない）した場合には、当該営業譲渡に伴い本利用規約に基づく契約上の地位、本利用規約に基づく権利および義務ならびに会員の登録事項その他の顧客情報を当該営業譲渡の譲受人に譲渡することができるものとし、会員は、かかる譲渡につき本項において予め同意したものとします。
            </li>
            <li>
              会員は、当社の書面による事前の承諾なく、本契約上の地位または本利用規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
            </li>
          </ol>
        </div>

        <h2>第22条（協議）</h2>
        <div className="text">
          <p>
            本利用規約に定めのない事項については、当社と会員の間で誠意をもって協議し解決を図るものとします。
          </p>
        </div>

        <h2>第23条（専属的合意管轄裁判所）</h2>
        <div className="text">
          <p>
            会員と当社の間で、本サービスに関する一切の紛争については、東京地方裁判所を第一審の専属的管轄裁判所とすることとします。
          </p>
        </div>
      </div>
    );
  }

  renderSign() {
    return (
      <div className="text">
        <p className="seitei">制定 : 2018年8月1日</p>
      </div>
    );
  }

  renderButton() {
    const { needToAgree } = this.props;
    const disabled = this.state.isAgreeButton;
    return needToAgree ? (
      <button onClick={this.handleAgreeButtonClick} disabled={disabled}>
        同意する
      </button>
    ) : (
      this.renderNavigation()
    );
  }
}

AgreementPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func),
  location: PropTypes.object,
  needToAgree: PropTypes.bool,
};

export default withRouter(
  connect(
    () => ({}),
    (dispatch) => ({
      actions: {
        goto: (pathname) => dispatch(push(pathname)),
      },
    })
  )(AgreementPage)
);

export function withAgreement(OriginalComponent) {
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class AgreementSwitch extends React.Component {
      static propTypes = {
        actions: PropTypes.objectOf(PropTypes.func).isRequired,
        authentication: PropTypes.object.isRequired,
      };

      render() {
        const { authentication } = this.props;
        if (!authentication.user.lastAgreedAt)
          return <AgreementPage needToAgree {...this.props} />;
        return <OriginalComponent {...this.props} />;
      }
    }
  );

  function mapStateToProps(state) {
    return {
      authentication: state.authentication,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(actions, dispatch),
    };
  }
}
