import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import FlatPrimaryButton from "../../../../../app/ui/button/FlatPrimaryButton";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import Textarea from "../../../../../app/ui/form/Textarea";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import FormGroupingDeleteButton from "../../../../../app/ui/button/FormGroupingDeleteButton";

const itemsName = "itemForms";

class FuneralMenuForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleItemAddClick = this.handleItemAddClick.bind(this);
    this.handleItemRemoveClick = this.handleItemRemoveClick.bind(this);
  }

  getItems() {
    const { bindingProps } = this.props;
    return bindingProps.form.getByName(itemsName);
  }

  appendItem(item) {
    const { bindingProps } = this.props;
    const org = this.getItems();
    bindingProps.onChange(itemsName, org.concat([item]));
  }

  removeItem(index) {
    const { bindingProps } = this.props;
    const org = this.getItems();
    bindingProps.onChange(
      itemsName,
      org.filter((_, i) => i !== index)
    );
  }

  handleItemAddClick() {
    this.appendItem({
      name: "",
      detail: "",
      fee: "",
    });
  }

  handleItemRemoveClick(index) {
    this.removeItem(index);
  }

  render() {
    return (
      <Fragment>
        {this.getItems().map((item, i) => {
          return this.renderMenu(item, i);
        })}
        {this.renderItemAdd()}
      </Fragment>
    );
  }

  renderItemAdd() {
    const { bindingProps } = this.props;
    return (
      <FlatPrimaryButton onClick={this.handleItemAddClick} {...bindingProps}>
        項目を追加する
      </FlatPrimaryButton>
    );
  }

  renderMenu(item, i) {
    const { bindingProps } = this.props;
    const namePrefix = `${itemsName}.${i}`;
    return (
      <FormGrouping
        key={i}
        title={
          <Fragment>
            メニュー{i + 1}
            <FormGroupingDeleteButton
              onClick={() => this.handleItemRemoveClick(i)}
            />
          </Fragment>
        }
      >
        <HorizontalLabelLayout labelText="サービス名" required>
          <InputTypeText name={`${namePrefix}.name`} {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="サービス説明" recommended>
          <Textarea name={`${namePrefix}.detail`} {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="料金" required>
          <ShortInputWidth>
            <UnitLayout unitText="円">
              <InputTypeNumber
                name={`${namePrefix}.fee`}
                hintText="0"
                {...bindingProps}
              />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

FuneralMenuForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
};

export default FuneralMenuForm;
