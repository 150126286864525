import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ServiceContentType from "../service/ServiceContentType";
import ServiceContentTypePrint from "../service/ServiceContentTypePrint";
import * as actions from "../../../state/service-search/actions";
import Sheet from "../search/print/Sheet";
import ServicePageReprintComment from "../service/ServicePageReprintComment";
import CenteredCircularProgress from "../../../app/ui/progress/CenteredCircularProgress";
import { withServiceSearchList } from "../../../state/service-search";

class ServiceDetailsPrintPage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields();
  }

  initializeFields() {
    this.serviceContentType = ServiceContentType.abstract;
  }

  componentDidMount() {
    const { serviceSearch, actions } = this.props;
    if (serviceSearch.list.length) {
      actions.fetchDetails(serviceSearch.list, this.serviceContentType);
    }
  }

  contents(contents) {
    return this.serviceContentType.selector(contents);
  }

  render() {
    const { serviceSearch } = this.props;
    if (
      serviceSearch.detailsIsLoading ||
      !Object.keys(serviceSearch.details).length
    )
      return <CenteredCircularProgress />;
    return serviceSearch.list.map((item) => this.renderSheet(item));
  }

  renderSheet(item) {
    const { serviceSearch } = this.props;
    const id = item.serviceId || item.id; // 活動通いの場の場合serviceId、それ以外id
    const contents = serviceSearch.details[id].contents;
    return (
      <Sheet key={item.id}>
        <ServiceContentTypePrint
          serviceSubId={item.serviceSubId}
          contents={this.contents(contents)}
        />
        <ServicePageReprintComment contents={this.contents(contents)} />
      </Sheet>
    );
  }
}

ServiceDetailsPrintPage.propTypes = {
  actions: PropTypes.object.isRequired,
  serviceSearch: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
};

export default withServiceSearchList(
  connect(mapStateToProps, mapDispatchToProps)(ServiceDetailsPrintPage)
);

function mapStateToProps(state) {
  return {
    serviceSearch: state.serviceSearch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
