import ActionType from "./reducer/ActionType";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import ApiPath from "../../app/fundamental/request/ApiPath";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
} from "../../app/fundamental/request";
import Parametername from "../../app/naming/Parametername";

export function makeCondition(search, serviceShubetsuMenu) {
  return {
    type: ActionType.MAKE_CONDITION,
    payload: {
      search,
      serviceShubetsuMenu,
    },
  };
}

export function clearList() {
  return {
    type: ActionType.CLEAR_LIST,
  };
}

export function setSearch(search) {
  return {
    type: ActionType.SET_SEARCH,
    payload: {
      search,
    },
  };
}

export function select(id) {
  return {
    type: ActionType.SELECT,
    payload: {
      id,
    },
  };
}

export function changeConditionServiceShubetsuCategory(
  code,
  checked,
  serviceShubetsuMenu
) {
  return {
    type: ActionType.CHANGE_CONDITION_SERVICE_SHUBETSU_CATEGORY,
    payload: {
      code,
      checked,
      serviceShubetsuMenu,
    },
  };
}

export function changeConditionServiceShubetsuMenu(
  code,
  checked,
  serviceShubetsuMenu
) {
  return {
    type: ActionType.CHANGE_CONDITION_SERVICE_SHUBETSU_MENU,
    payload: {
      code,
      checked,
      serviceShubetsuMenu,
    },
  };
}

export function changeConditionFolders(folders, serviceShubetsuMenu, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.CHANGE_CONDITION_FOLDERS,
      payload: {
        folders,
        serviceShubetsuMenu,
      },
    });
    setTimeout(callback);
  };
}

export function changeCondition(name, value) {
  return {
    type: ActionType.CHANGE_CONDITION,
    payload: {
      name,
      value,
    },
  };
}

export function savaConditionBuffer(name, value, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SAVE_CONDITION_BUFFER,
      payload: {
        name,
        value,
      },
    });
    setTimeout(callback);
  };
}

export function requestBySearch(search, serviceShubetsuMenu, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REQUEST,
      payload: {
        search,
      },
    });
    requestServiceSearchResult(dispatch, search, serviceShubetsuMenu, callback);
  };
}

// 閲覧検索 市区町村地域一覧取得
export function fetchBrowseAreaCities(prefCode) {
  return (dispatch) => {
    dispatch({
      type: ActionType.FETCH_BROWSE_AREA_CITIES,
    });
    const searchParam = new URLSearchParams();
    if (prefCode != null) searchParam.set("prefCode", prefCode);
    // ここに追加予定
    const url = `${ApiPath.api_browse_area_cities}?${searchParam.toString()}`;
    const onSuccess = (json) => {
      dispatch({
        type: ActionType.FETCH_BROWSE_AREA_CITIES_FINISHED,
        payload: {
          areaCities: json,
        },
      });
    };
    ApiRequest.get(url).request(
      defaultResponseHandler(dispatch, onSuccess),
      defaultUnauthorizedHander(dispatch)
    );
  };
}

// 閲覧検索 町名地域一覧取得
export function fetchBrowseAreaTowns(cityAreaCodes) {
  return (dispatch) => {
    dispatch({
      type: ActionType.FETCH_BROWSE_AREA_CITIES,
    });
    const searchParam = new URLSearchParams();
    if (cityAreaCodes) {
      Object.keys(cityAreaCodes).forEach((code) =>
        searchParam.append("cityCode", code)
      );
    }
    const url = `${ApiPath.api_browse_area_towns}?${searchParam.toString()}`;
    const onSuccess = (json) => {
      dispatch({
        type: ActionType.FETCH_BROWSE_AREA_TOWNS_FINISHED,
        payload: {
          areaTowns: json,
        },
      });
    };
    ApiRequest.get(url).request(
      defaultResponseHandler(dispatch, onSuccess),
      defaultUnauthorizedHander(dispatch)
    );
  };
}

// 閲覧検索 選択地域名取得
export function fetchSelectedBrowseAreaLabel(cityAreaCodes, townAreaCodes) {
  return (dispatch) => {
    const searchParam = new URLSearchParams();
    if (cityAreaCodes) {
      Object.keys(cityAreaCodes).forEach((code) =>
        searchParam.append(Parametername.cityArea, code)
      );
    }
    if (townAreaCodes) {
      Object.keys(townAreaCodes).forEach((code) =>
        searchParam.append(Parametername.townArea, code)
      );
    }
    const url = `${
      ApiPath.api_browse_area_label_cities
    }?${searchParam.toString()}`;
    const onSuccess = (json) => {
      dispatch({
        type: ActionType.FETCH_BROWSE_AREA_LABEL_FINISHED,
        payload: {
          areaLabel: json,
        },
      });
    };
    ApiRequest.get(url).request(
      defaultResponseHandler(dispatch, onSuccess),
      defaultUnauthorizedHander(dispatch)
    );
  };
}

function requestServiceSearchResult(
  dispatch,
  search,
  serviceShubetsuMenu,
  callback = () => {}
) {
  const url = `${ApiPath.api_services}?${search}`;
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.REQUEST_FINISHED,
      payload: {
        list: json.list,
        pagination: json.pagination,
        search: json.search,
        prevSearch: json.prevSearch,
        nextSearch: json.nextSearch,
        serviceShubetsuMenu,
      },
    });
    setTimeout(() => callback(json.search));
  };
  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}

// 詳細取得
export function fetchDetails(list, serviceContentType) {
  return (dispatch) => {
    dispatch({
      type: ActionType.FETCH_DETAIL_ITEM,
    });
    requestContents(
      dispatch,
      list.map((content) => content.serviceId || content.id), // 活動通いの場の分岐
      serviceContentType
    );
  };
}

function requestContents(dispatch, ids, serviceContentType) {
  const url = createUrl(ids);
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.FETCH_DETAIL_ITEM_FINISHED,
      payload: {
        serviceContentType: serviceContentType,
        details: json,
      },
    });
  };

  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}

function createUrl(ids) {
  const searchParams = new URLSearchParams();
  ids.map((id) => searchParams.append("id", id));
  return `${ApiPath.api_services_abstracts}?${searchParams.toString()}`;
}
