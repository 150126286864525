import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "../../../../state/jigyosho-form/actions";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import FindIcon from "@material-ui/icons/FindReplace";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import { enterKeyHandler, withInputFeedback } from "../../../../app/ui/form";
import HorizontalLabelLayout from "../../../../app/ui/form/HorizontalLabelLayout";
import FormGrouping from "../../../../app/ui/form/FormGrouping";
import InputTypeText from "../../../../app/ui/form/InputTypeText";
import { Card, IconButton } from "@material-ui/core";
import Boundary from "../../../../app/ui/layout/Boundary";
import Right from "../../../../app/ui/layout/Right";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";
import FlatSecondaryButton from "../../../../app/ui/button/FlatSecondaryButton";
import CenteredCircularProgress from "../../../../app/ui/progress/CenteredCircularProgress";
import { zipcodeToAddress } from "../../../../state/zipcode-search/actions";
import ShortInputWidth from "../../../../app/ui/form/ShortInputWidth";
import GridRow from "../../../../app/ui/grid/GridRow";
import GridCell from "../../../../app/ui/grid/GridCell";
import {
  MASTER_DATA_BUNDLE_FOR_JIGYOSHO,
  withMasterData,
} from "../../../../state/master-data";
import StandardSelect from "../../../../app/ui/form/StandardSelect";
import DummyLabel from "../../../../app/ui/form/DummyLabel";
import { hokenTekiyoTypeValueOf } from "../../../../app/domain/jigyosho/HokenTekiyoType";
import { excludeMimamoriSuishinJigyo } from "../../../../app/domain/jigyosho/ServiceShubetsuType";
import InputTypeCheckbox from "../../../../app/ui/form/InputTypeCheckbox";
import AgentCollectionConfirmationDialog from "./AgentCollectionConfirmationDialog";
import { packInHokengaiService } from "../../jigyosho/add/JigyoshoAddPage";
import RatioBlock from "../../../../app/ui/layout/RatioBlock";
import StaticLabel from "../../../../app/ui/form/StaticLabel";

class AgentCollectionAddPage extends React.Component {
  state = {
    dialogList: null,
    dialogOpen: false,
  };

  constructor(props) {
    super(props);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleEnterKeyDownOnYubimbango = this.handleEnterKeyDownOnYubimbango.bind(
      this
    );
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.handleSaveProcessDone = this.handleSaveProcessDone.bind(this);
    this.handlePossibleDeplicationDetected = this.handlePossibleDeplicationDetected.bind(
      this
    );
    this.handlePossibleDeplicationRecognized = this.handlePossibleDeplicationRecognized.bind(
      this
    );
    this.handlePossibleDeplicationAccepted = this.handlePossibleDeplicationAccepted.bind(
      this
    );
  }

  componentDidMount() {
    this.props.onDidMount();
  }

  handleBackButtonClick() {
    this.props.onBackButtonClick(this.props.jigyoshoList.search);
  }

  handleEnterKeyDownOnYubimbango() {
    const zipcode = this.props.jigyoshoForm.form.getByName("yubimbango");
    this.props.onZipCodeSet(zipcode, (address) => {
      this.props.onFormChange("todofuken", address.pref);
      this.props.onFormChange("shikuchoson", address.address);
    });
  }

  handleSaveButtonClick() {
    this.props.onSaveButtonClick(
      this.props.jigyoshoForm.form,
      this.handleSaveProcessDone
    );
  }

  handleSaveProcessDone(form) {
    if (form.id) return this.props.onSaveProcessDone(form.id);
    this.handlePossibleDeplicationDetected(
      form.possibleDuplicatingJigyoshoList
    );
  }

  handlePossibleDeplicationDetected(list) {
    this.setState({
      dialogList: list,
      dialogOpen: true,
    });
  }

  handlePossibleDeplicationRecognized() {
    this.setState({
      dialogOpen: false,
    });
  }

  handlePossibleDeplicationAccepted() {
    const { onFormChange: changeForm } = this.props;
    this.setState({
      dialogOpen: false,
    });
    changeForm("force", true, () => this.handleSaveButtonClick());
  }

  render() {
    return (
      <Fragment>
        <Boundary>
          <SideMargin>
            {this.renderHead()}
            {this.renderNavigation()}
            {this.renderForm()}
          </SideMargin>
        </Boundary>
        {this.renderConfirmationDialog()}
      </Fragment>
    );
  }

  renderHead() {
    return <BackOfficeHead>事業所追加</BackOfficeHead>;
  }

  renderNavigation() {
    return (
      <BackOfficeNavigation>
        <FlatPrimaryButton onClick={this.handleBackButtonClick}>
          <BackIcon />
          戻る
        </FlatPrimaryButton>
      </BackOfficeNavigation>
    );
  }

  renderForm() {
    if (this.formIsLoading()) return <CenteredCircularProgress />;
    if (this.formIsInvalid()) return null;
    const bindingProps = this.createBindingProps();
    return (
      <Card>
        <SideMargin top bottom>
          <FormGrouping title="事業所情報">
            <HorizontalLabelLayout labelText="運営法人" required>
              <InputTypeText name="uneihojin" {...bindingProps} optional />
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="事業所・活動団体名" required>
              <InputTypeText name="jigyoshomei" {...bindingProps} />
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="サービス種別" required>
              <ShortInputWidth>
                <StandardSelect
                  name="serviceShubetsuCode"
                  blank
                  categorize
                  options={this.selectableServiceShubetsu()}
                  {...bindingProps}
                />
              </ShortInputWidth>
            </HorizontalLabelLayout>
          </FormGrouping>
          <FormGrouping title="連絡先情報">
            <HorizontalLabelLayout labelText="☎ 電話番号" required>
              <GridRow>
                <GridCell>
                  <InputTypeText
                    name="denwabango"
                    hintText="00-0000-0000"
                    {...bindingProps}
                  />
                </GridCell>
                <GridCell grow={2}>
                  <div>
                    <RatioBlock divide={12}>
                      <Right>（ </Right>
                    </RatioBlock>
                    <RatioBlock grow={6} divide={12}>
                      <InputTypeText
                        name="denwabangoToiawasesaki"
                        hintText="例）ミルモネット問い合わせ係"
                        {...bindingProps}
                      />
                    </RatioBlock>
                    <RatioBlock divide={12}>）</RatioBlock>
                  </div>
                </GridCell>
              </GridRow>
              <StaticLabel>
                ※
                お問合せ先が店舗・事業所・団体と異なる場合お問合せ先をご記入ください
                <br />
              </StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="">
              <InputTypeCheckbox
                name="denwabangoFaxHikokai"
                labelText="電話番号を公開しない"
                {...bindingProps}
              />
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="〒 郵便番号" required>
              <GridRow>
                <GridCell>
                  <InputTypeText
                    name="yubimbango"
                    hintText="000-0000"
                    {...bindingProps}
                    onKeyDown={enterKeyHandler(
                      this.handleEnterKeyDownOnYubimbango
                    )}
                  />
                </GridCell>
                <GridCell grow={2}>
                  <IconButton onClick={this.handleEnterKeyDownOnYubimbango}>
                    <FindIcon />
                  </IconButton>
                </GridCell>
              </GridRow>
              <ShortInputWidth></ShortInputWidth>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="都道府県" required>
              <DummyLabel name="todofuken" {...bindingProps} />
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="市区町村" required>
              <ShortInputWidth>
                <InputTypeText name="shikuchoson" {...bindingProps} />
              </ShortInputWidth>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="番地" required>
              <ShortInputWidth>
                <InputTypeText
                  name="banchi"
                  hintText="0番地"
                  {...bindingProps}
                />
              </ShortInputWidth>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="建物名">
              <InputTypeText name="tatemonomei" {...bindingProps} />
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="">
              <InputTypeCheckbox
                name="jushoHikokai"
                labelText="住所を公開しない"
                {...bindingProps}
              />
            </HorizontalLabelLayout>
          </FormGrouping>
          <Right>
            <SpaceOut>
              <FlatSecondaryButton onClick={this.handleBackButtonClick}>
                キャンセル
              </FlatSecondaryButton>
            </SpaceOut>
            <SpaceOut>
              <RaisedPrimaryButton
                disabled={this.formIsSubmitting()}
                onClick={this.handleSaveButtonClick}
              >
                保存する
              </RaisedPrimaryButton>
            </SpaceOut>
          </Right>
        </SideMargin>
      </Card>
    );
  }

  renderConfirmationDialog() {
    const props = {
      // state
      list: this.state.dialogList,
      open: this.state.dialogOpen,
      // event handlers
      onAddButtonClick: this.handlePossibleDeplicationAccepted,
      onClose: this.handlePossibleDeplicationRecognized,
    };
    return <AgentCollectionConfirmationDialog {...props} />;
  }

  formIsLoading() {
    return this.props.jigyoshoForm.formIsLoading;
  }

  formIsInvalid() {
    return !this.props.jigyoshoForm.form.typeIs("agent");
  }

  formIsSubmitting() {
    return this.props.jigyoshoForm.formIsSubmitting;
  }

  createBindingProps() {
    return {
      form: this.props.jigyoshoForm.form,
      onChange: this.props.onFormChange,
    };
  }

  selectableServiceShubetsu() {
    const { authentication } = this.props;
    const { user } = authentication;
    return user.canHandleMimamoriSuishinJigyo()
      ? this.props.masterData.serviceShubetsu
          .filter(
            (master) =>
              !hokenTekiyoTypeValueOf(
                master.category.hokenTekiyoType
              ).isHokennai()
          )
          .map(packInHokengaiService)
      : this.props.masterData.serviceShubetsu
          .filter(
            (master) =>
              !hokenTekiyoTypeValueOf(
                master.category.hokenTekiyoType
              ).isHokennai()
          )
          .map(packInHokengaiService)
          .filter((it) => excludeMimamoriSuishinJigyo(it.code));
  }
}

AgentCollectionAddPage.propTypes = {
  // state
  jigyoshoForm: PropTypes.object.isRequired,
  jigyoshoList: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  // action
  onDidMount: PropTypes.func.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
  onSaveButtonClick: PropTypes.func.isRequired,
  onSaveProcessDone: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onZipCodeSet: PropTypes.func.isRequired,
  authentication: PropTypes.object.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_JIGYOSHO)(
  withInputFeedback(
    connect(mapStateToProps, mapDispatchToProps)(AgentCollectionAddPage)
  )
);

function mapStateToProps(state) {
  return {
    jigyoshoForm: state.jigyoshoForm,
    jigyoshoList: state.jigyoshoList,
    masterData: state.masterData,
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onDidMount: () => {
      dispatch(actions.requestAgentCollectionBlankForm());
    },
    onBackButtonClick: (search) => {
      dispatch(
        push({
          pathname: Pathname.backoffice_agent_collection,
          search,
        })
      );
    },
    onSaveButtonClick: (form, callback) => {
      dispatch(actions.submitToCollect(form, callback));
    },
    onSaveProcessDone: (id) => {
      dispatch(
        push(Pathname.backoffice_agent_collection_id_status.replace(/:id/, id))
      );
    },
    onFormChange: (...args) => {
      dispatch(actions.changeForm(...args));
    },
    onZipCodeSet: (zipcode, callback) => {
      dispatch(zipcodeToAddress(zipcode, callback));
    },
  };
}
