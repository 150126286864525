import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InputTypeTextWithClearButton from "../../../../app/ui/form/InputTypeTextWithClearButton";
import { enterKeyHandler } from "../../../../app/ui/form";
import { push } from "react-router-redux";
import * as actions from "../../../../state/hojin-list/actions";
import GridRow from "../../../../app/ui/grid/GridRow";
import GridCell from "../../../../app/ui/grid/GridCell";
import StaticLabel from "../../../../app/ui/form/StaticLabel";
import { conditionConverter } from "../../../../state/hojin-list/query-string";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";

class HojinListCondition extends React.Component {
  constructor(props) {
    super(props);
    this.handleEnterKeyDown = this.handleEnterKeyDown.bind(this);
    this.handleClearButtonClick = this.handleClearButtonClick.bind(this);
  }

  handleEnterKeyDown() {
    const { actions, condition } = this.props;
    actions.reSearch(condition);
  }

  handleClearButtonClick() {
    const { actions } = this.props;
    actions.changeCondition("keyword", "", () => {
      actions.reSearch(this.props.condition);
    });
  }

  createBindingProps() {
    const { actions, condition } = this.props;
    return {
      form: condition,
      onChange: actions.changeCondition,
    };
  }

  render() {
    return (
      <div>
        <GridRow>
          <GridCell>
            <InputTypeTextWithClearButton
              labelText="法人名・担当者氏名・電話番号"
              name="keyword"
              onKeyDown={enterKeyHandler(this.handleEnterKeyDown)}
              onClearButtonClick={this.handleClearButtonClick}
              {...this.createBindingProps()}
            />
          </GridCell>
          <GridCell grow={2} className="search-area">
            <RaisedPrimaryButton onClick={this.handleEnterKeyDown}>
              検索
            </RaisedPrimaryButton>
          </GridCell>
        </GridRow>
      </div>
    );
  }
}

HojinListCondition.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  condition: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HojinListCondition);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      changeCondition: (name, value, callback) =>
        dispatch(actions.changeCondition(name, value, callback)),
      reSearch: (condition) => {
        const search = conditionConverter(condition).toSearch();
        dispatch(push({ search }));
      },
    },
  };
}
