import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import { OPTIONS_AVAILABILITY } from "../../../../../app/ui/form";
import Textarea from "../../../../../app/ui/form/Textarea";
import {
  MASTER_DATA_BUNDLE_FOR_SHORTSTAY,
  withMasterData,
} from "../../../../../state/master-data";
import Mount from "../../../../../app/ui/layout/Mount";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import StaticLabel from "../../../../../app/ui/form/StaticLabel";
import RatioBlock from "../../../../../app/ui/layout/RatioBlock";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";

class ShortstayRehabilitationForm extends React.Component {
  render() {
    return (
      <Fragment>
        {this.renderRihab()}
        {this.renderKobetsu()}
        {this.renderShudan()}
        {this.renderKankyo()}
        {this.renderBiko()}
        {this.renderKaigohoshu()}
      </Fragment>
    );
  }

  getLabel() {
    const { serviceShubetsuCode } = this.props;
    if (
      ServiceShubetsuType.短期入所生活介護.code.includes(serviceShubetsuCode)
    ) {
      return "機能訓練";
    } else {
      return "リハビリテーション";
    }
  }

  renderRihab() {
    const { bindingProps } = this.props;
    const disabled = !OptionsRadio.checked(
      bindingProps.form.getByName("rehabilitationUmu")
    );
    return (
      <FormGrouping title={this.getLabel()}>
        <HorizontalLabelLayout
          labelText={<span>{this.getLabel()}の有無</span>}
          required
        >
          <OptionsRadio
            name="rehabilitationUmu"
            split={6}
            options={OPTIONS_AVAILABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="特徴">
          <Textarea name="tokucho" disabled={disabled} {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderKobetsu() {
    const { bindingProps, masterData } = this.props;
    const disabled = !OptionsRadio.checked(
      bindingProps.form.getByName("rehabilitationUmu")
    );
    const isActive = OptionsRadio.checked(
      bindingProps.form.getByName("kobetsutaio")
    );
    return (
      <FormGrouping title="個別機能訓練(個別対応)">
        <HorizontalLabelLayout labelText="個別対応" required>
          <OptionsRadio
            name="kobetsutaio"
            split={6}
            options={OPTIONS_AVAILABILITY}
            disabled={disabled}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <Mount if={isActive}>
          <HorizontalLabelLayout labelText="担当制" required>
            <OptionsRadio
              name="tantousei"
              split={6}
              options={OPTIONS_AVAILABILITY}
              disabled={disabled}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="内容">
            <OptionsCheckbox
              name="rehabilitationKobetsuKinokunrennaiyo"
              options={masterData.shortstay_KobetsuKinokunrennaiyo}
              disabled={disabled}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="時間(分)">
            <ShortInputWidth>
              <UnitLayout unitText="分">
                <InputTypeNumber
                  name="kobetsutaioJikan"
                  disabled={disabled}
                  {...bindingProps}
                />
              </UnitLayout>
            </ShortInputWidth>
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="療法">
            <OptionsCheckbox
              name="rehabilitationRiryoho"
              options={masterData.shortstay_Ryoho}
              disabled={disabled}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
        </Mount>
      </FormGrouping>
    );
  }

  renderShudan() {
    const { bindingProps, masterData } = this.props;
    const disabled = !OptionsRadio.checked(
      bindingProps.form.getByName("rehabilitationUmu")
    );
    const isActive = OptionsRadio.checked(
      bindingProps.form.getByName("shudantaio")
    );
    return (
      <FormGrouping title="個別機能訓練(集団対応)">
        <HorizontalLabelLayout labelText="集団対応" required>
          <OptionsRadio
            name="shudantaio"
            split={6}
            options={OPTIONS_AVAILABILITY}
            disabled={disabled}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <Mount if={isActive}>
          <HorizontalLabelLayout labelText="内容">
            <OptionsCheckbox
              name="rehabilitationShudanKinokunrennaiyo"
              options={masterData.shortstay_ShudanKinokunrennaiyo}
              disabled={disabled}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="人数">
            <ShortInputWidth>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name="shudantaioNinzu"
                  disabled={disabled}
                  {...bindingProps}
                />
              </UnitLayout>
            </ShortInputWidth>
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="時間(分)">
            <ShortInputWidth>
              <UnitLayout unitText="分">
                <InputTypeNumber
                  name="shudantaioJikan"
                  disabled={disabled}
                  {...bindingProps}
                />
              </UnitLayout>
            </ShortInputWidth>
          </HorizontalLabelLayout>
        </Mount>
      </FormGrouping>
    );
  }

  renderKankyo() {
    const { bindingProps, masterData } = this.props;
    const disabled = !OptionsRadio.checked(
      bindingProps.form.getByName("rehabilitationUmu")
    );
    return (
      <FormGrouping title="環境・設備">
        <HorizontalLabelLayout labelText="リハビリ機材">
          <OptionsCheckbox
            name="rehabilitationRehabilitationKizai"
            categorize
            options={masterData.shortstay_RehabiliKizai}
            disabled={disabled}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="訓練場所">
          <OptionsCheckbox
            name="rehabilitationKunrembasho"
            options={masterData.shortstay_Kunrembasho}
            disabled={disabled}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderBiko() {
    const { bindingProps } = this.props;
    const disabled = !OptionsRadio.checked(
      bindingProps.form.getByName("rehabilitationUmu")
    );
    return (
      <FormGrouping title="備考">
        <HorizontalLabelLayout labelText="">
          <Textarea name="biko" disabled={disabled} {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderKaigohoshu() {
    return (
      <FormGrouping title="介護報酬加算">
        <RatioBlock divide={18}></RatioBlock>
        <RatioBlock grow={17} divide={18}>
          <StaticLabel>
            ※介護報酬加算は、料金加算情報から入力してください。
            <br />
          </StaticLabel>
        </RatioBlock>
      </FormGrouping>
    );
  }
}

ShortstayRehabilitationForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_SHORTSTAY)(
  ShortstayRehabilitationForm
);
