import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../../state/jigyosho-form/actions";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import FindIcon from "@material-ui/icons/FindReplace";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import { enterKeyHandler, withInputFeedback } from "../../../../app/ui/form";
import HorizontalLabelLayout from "../../../../app/ui/form/HorizontalLabelLayout";
import FormGrouping from "../../../../app/ui/form/FormGrouping";
import InputTypeText from "../../../../app/ui/form/InputTypeText";
import { Card, IconButton } from "@material-ui/core";
import Boundary from "../../../../app/ui/layout/Boundary";
import Right from "../../../../app/ui/layout/Right";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";
import FlatSecondaryButton from "../../../../app/ui/button/FlatSecondaryButton";
import CenteredCircularProgress from "../../../../app/ui/progress/CenteredCircularProgress";
import { zipcodeToAddress } from "../../../../state/zipcode-search/actions";
import ShortInputWidth from "../../../../app/ui/form/ShortInputWidth";
import GridRow from "../../../../app/ui/grid/GridRow";
import GridCell from "../../../../app/ui/grid/GridCell";
import {
  MASTER_DATA_BUNDLE_FOR_JIGYOSHO,
  withMasterData,
} from "../../../../state/master-data";
import StandardSelect from "../../../../app/ui/form/StandardSelect";
import { hokenTekiyoTypeValueOf } from "../../../../app/domain/jigyosho/HokenTekiyoType";
import InputTypeCheckbox from "../../../../app/ui/form/InputTypeCheckbox";
import Mount from "../../../../app/ui/layout/Mount";
import RatioBlock from "../../../../app/ui/layout/RatioBlock";
import StaticLabel from "../../../../app/ui/form/StaticLabel";
import {
  excludeMimamoriSuishinJigyo,
  serviceShubetsuTypeValueOf,
} from "../../../../app/domain/jigyosho/ServiceShubetsuType";
import DummyLabel from "../../../../app/ui/form/DummyLabel";

class JigyoshoAddPage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleEnterKeyDownOnYubimbango = this.handleEnterKeyDownOnYubimbango.bind(
      this
    );
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.reloadListPage = this.reloadListPage.bind(this);
  }

  initializeFields(props) {
    const { location } = props;
    const searchParams = new URLSearchParams(location.search);
    const groupId = searchParams.get("groupId");
    if (groupId != null) this.groupId = +groupId; // parseInt
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.requestBlankForm(this.groupId);
  }

  handleBackButtonClick() {
    const { actions, location } = this.props;
    actions.gotoJoinPage(location.search);
  }

  handleEnterKeyDownOnYubimbango() {
    const { actions, jigyoshoForm } = this.props;
    const zipcode = jigyoshoForm.form.getByName("yubimbango");
    actions.zipcodeToAddress(zipcode, (address) => {
      actions.changeForm("todofuken", address.pref);
      actions.changeForm("shikuchoson", address.address);
    });
  }

  handleSaveButtonClick() {
    const { actions, jigyoshoForm } = this.props;
    actions.submitToAdd(jigyoshoForm.form, this.reloadListPage);
  }

  reloadListPage() {
    const { actions, jigyoshoList } = this.props;
    actions.reloadListPage(jigyoshoList.search);
  }

  createBindingProps() {
    const { actions, jigyoshoForm } = this.props;
    const { form } = jigyoshoForm;
    const { changeForm: onChange } = actions;
    return { form, onChange };
  }

  render() {
    return (
      <Boundary>
        <SideMargin>
          {this.renderHead()}
          {this.renderNavigation()}
          {this.renderForm()}
        </SideMargin>
      </Boundary>
    );
  }

  renderHead() {
    return <BackOfficeHead>事業所追加</BackOfficeHead>;
  }

  renderNavigation() {
    return (
      <BackOfficeNavigation>
        <FlatPrimaryButton onClick={this.handleBackButtonClick}>
          <BackIcon />
          戻る
        </FlatPrimaryButton>
      </BackOfficeNavigation>
    );
  }

  renderForm() {
    const { jigyoshoForm } = this.props;
    if (jigyoshoForm.formIsLoading) return <CenteredCircularProgress />;
    if (!jigyoshoForm.form.typeIs("blank")) return null;
    const bindingProps = this.createBindingProps();

    return (
      <Card>
        <SideMargin top bottom>
          <FormGrouping title="事業所情報">
            <HorizontalLabelLayout labelText="運営法人" required>
              <InputTypeText name="uneihojin" {...bindingProps} />
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="事業所・活動団体名" required>
              <InputTypeText name="jigyoshomei" {...bindingProps} />
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="サービス種別" required>
              <ShortInputWidth>
                <StandardSelect
                  name="serviceShubetsuCode"
                  blank
                  categorize
                  options={this.selectableServiceShubetsu()}
                  {...bindingProps}
                />
              </ShortInputWidth>
            </HorizontalLabelLayout>
            <Mount
              if={
                this.objectIsHokennaiService() &&
                this.objectIsHissuJigyoshobango()
              }
              then={() => (
                <HorizontalLabelLayout labelText="事業所番号" required>
                  <ShortInputWidth>
                    <InputTypeText
                      name="jigyoshobango"
                      hintText="0000000000"
                      {...bindingProps}
                    />
                  </ShortInputWidth>
                </HorizontalLabelLayout>
              )}
            />
          </FormGrouping>
          <FormGrouping title="連絡先情報">
            <HorizontalLabelLayout labelText="☎ 電話番号" required>
              <Mount
                if={!this.objectIsHokengaiService()}
                then={() => (
                  <ShortInputWidth>
                    <InputTypeText
                      name="denwabango"
                      hintText="00-0000-0000"
                      {...bindingProps}
                    />
                  </ShortInputWidth>
                )}
              />
              <Mount
                if={this.objectIsHokengaiService()}
                then={() => (
                  <Fragment>
                    <GridRow>
                      <GridCell>
                        <InputTypeText
                          name="denwabango"
                          hintText="00-0000-0000"
                          {...bindingProps}
                        />
                      </GridCell>
                      <GridCell grow={2}>
                        <div>
                          <RatioBlock divide={12}>
                            <Right>（ </Right>
                          </RatioBlock>
                          <RatioBlock grow={6} divide={12}>
                            <InputTypeText
                              name="denwabangoToiawasesaki"
                              hintText="例）ミルモネット問い合わせ係"
                              {...bindingProps}
                            />
                          </RatioBlock>
                          <RatioBlock divide={12}>）</RatioBlock>
                        </div>
                      </GridCell>
                    </GridRow>
                    <StaticLabel>
                      ※
                      お問合せ先が店舗・事業所・団体と異なる場合お問合せ先をご記入ください
                      <br />
                    </StaticLabel>
                  </Fragment>
                )}
              />
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="">
              <InputTypeCheckbox
                name="denwabangoFaxHikokai"
                labelText="電話番号を公開しない"
                {...bindingProps}
              />
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="〒 郵便番号" required>
              <GridRow>
                <GridCell>
                  <InputTypeText
                    name="yubimbango"
                    hintText="000-0000"
                    {...bindingProps}
                    onKeyDown={enterKeyHandler(
                      this.handleEnterKeyDownOnYubimbango
                    )}
                  />
                </GridCell>
                <GridCell grow={2}>
                  <IconButton onClick={this.handleEnterKeyDownOnYubimbango}>
                    <FindIcon />
                  </IconButton>
                </GridCell>
              </GridRow>
              <ShortInputWidth></ShortInputWidth>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="都道府県" required>
              <DummyLabel name="todofuken" {...bindingProps} />
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="市区町村" required>
              <ShortInputWidth>
                <InputTypeText name="shikuchoson" {...bindingProps} />
              </ShortInputWidth>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="番地" required>
              <ShortInputWidth>
                <InputTypeText
                  name="banchi"
                  hintText="0番地"
                  {...bindingProps}
                />
              </ShortInputWidth>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="建物名">
              <InputTypeText name="tatemonomei" {...bindingProps} />
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="">
              <InputTypeCheckbox
                name="jushoHikokai"
                labelText="住所を公開しない"
                {...bindingProps}
              />
            </HorizontalLabelLayout>
          </FormGrouping>
          <Right>
            <SpaceOut>
              <FlatSecondaryButton onClick={this.handleBackButtonClick}>
                キャンセル
              </FlatSecondaryButton>
            </SpaceOut>
            <SpaceOut>
              <RaisedPrimaryButton
                disabled={jigyoshoForm.formIsSubmitting}
                onClick={this.handleSaveButtonClick}
              >
                保存する
              </RaisedPrimaryButton>
            </SpaceOut>
          </Right>
        </SideMargin>
      </Card>
    );
  }

  hokenTekiyoType() {
    const { jigyoshoForm, masterData } = this.props;
    return masterData.serviceShubetsu
      .filter(
        (it) => it.code == jigyoshoForm.form.getByName("serviceShubetsuCode")
      )
      .map((it) => it.category.hokenTekiyoType)
      .map((it) => hokenTekiyoTypeValueOf(it))[0];
  }

  objectIsHokennaiService() {
    const hokenTekiyoType = this.hokenTekiyoType();
    return hokenTekiyoType && hokenTekiyoType.isHokennai();
  }

  objectIsHokengaiService() {
    const hokenTekiyoType = this.hokenTekiyoType();
    return hokenTekiyoType && hokenTekiyoType.isHokengai();
  }

  selectableServiceShubetsu() {
    const { authentication, masterData } = this.props;
    const { user } = authentication;
    return user.canHandleMimamoriSuishinJigyo()
      ? masterData.serviceShubetsu.map(packInHokengaiService)
      : masterData.serviceShubetsu
          .map(packInHokengaiService)
          .filter((it) => excludeMimamoriSuishinJigyo(it.code));
  }

  objectIsHissuJigyoshobango() {
    const { jigyoshoForm } = this.props;
    const code = jigyoshoForm.form.getByName("serviceShubetsuCode");
    return serviceShubetsuTypeValueOf(code).hissuJigyoshobango;
  }
}

JigyoshoAddPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  jigyoshoForm: PropTypes.object.isRequired,
  jigyoshoList: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  authentication: PropTypes.object.isRequired,
};

export function packInHokengaiService(option) {
  const categoryMapper = (category) => {
    if (option.shienCenter)
      return { code: "shienCenter", label: "支援組織・推進団体" };
    if (hokenTekiyoTypeValueOf(category.hokenTekiyoType).isHokennai())
      return category;
    return { code: "hokengaiservice", label: "保険外サービス" };
  };
  return {
    ...option,
    label: option.wamnet,
    category: categoryMapper(option.category),
  };
}

export default withMasterData(MASTER_DATA_BUNDLE_FOR_JIGYOSHO)(
  withInputFeedback(
    connect(mapStateToProps, mapDispatchToProps)(JigyoshoAddPage)
  )
);

function mapStateToProps(state) {
  return {
    jigyoshoForm: state.jigyoshoForm,
    jigyoshoList: state.jigyoshoList,
    masterData: state.masterData,
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      zipcodeToAddress: (zipcode, callback) =>
        dispatch(zipcodeToAddress(zipcode, callback)),
      gotoJoinPage: (search = "") =>
        dispatch(
          push({
            pathname: Pathname.backoffice_jigyosho_join,
            search,
          })
        ),
      reloadListPage: (search = "") => {
        const searchParams = new URLSearchParams(search);
        searchParams.set("timestamp", new Date().getTime());
        search = searchParams.toString();
        dispatch(
          push({
            pathname: Pathname.backoffice_jigyosho,
            search,
          })
        );
      },
    },
  };
}
