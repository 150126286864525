import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Boundary from "../../../../app/ui/layout/Boundary";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import * as jigyoshoStatusActions from "../../../../state/jigyosho-status/actions";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_JIGYOSHO,
} from "../../../../state/master-data";
import GridRow from "../../../../app/ui/grid/GridRow";
import GridCell from "../../../../app/ui/grid/GridCell";
import JigyoshoStatusButton from "./JigyoshoStatusButton";
import {
  jigyoshoAttributeTypeValueOf,
  jigyoshoAttributeTypeValues,
  jigyoshoAttributeLabelOf,
} from "../../../../app/domain/jigyosho/JigyoshoAttributeType";
import SplitArrayTable from "../../../../app/util/SplitArrayTable";
import LockIcon from "@material-ui/icons/Lock";
import FileIcon from "@material-ui/icons/Note";
import PhotoIcon from "@material-ui/icons/CameraAlt";
import PageviewIcon from "@material-ui/icons/Pageview";
import { css } from "aphrodite";
import styles from "./JigyoshoStatusComponent.css";
import CenteredCircularProgress from "../../../../app/ui/progress/CenteredCircularProgress";
import Right from "../../../../app/ui/layout/Right";
import Mount from "../../../../app/ui/layout/Mount";
import { TableBody, TableHead, TableRow, TableCell } from "@material-ui/core";
import FixedTable from "../../../../app/ui/table/FixedTable";
import ServiceShubetsuType from "../../../../app/domain/jigyosho/ServiceShubetsuType";

class JigyoshoStatusComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handlePreviewButtonClick = this.handlePreviewButtonClick.bind(this);
    this.handlePhotoButtonClick = this.handlePhotoButtonClick.bind(this);
    this.handleFlierButtonClick = this.handleFlierButtonClick.bind(this);
    this.handleAttributeButtonClick = this.handleAttributeButtonClick.bind(
      this
    );
  }

  componentDidMount() {
    this.props.onDidMount(this.props.id);
  }

  handleBackButtonClick() {
    this.props.onBackButtonClick();
  }

  handlePreviewButtonClick() {
    this.props.onPreviewButtonClick(this.props.id);
  }

  handlePhotoButtonClick() {
    const {
      serviceShubetsuCategoryCode,
      serviceShubetsuCode,
    } = this.props.jigyoshoStatus;
    this.props.onPhotoButtonClick(
      this.props.id,
      serviceShubetsuCategoryCode,
      serviceShubetsuCode
    );
  }

  handleFlierButtonClick() {
    const {
      serviceShubetsuCategoryCode,
      serviceShubetsuCode,
    } = this.props.jigyoshoStatus;
    this.props.onFlierButtonClick(
      this.props.id,
      serviceShubetsuCategoryCode,
      serviceShubetsuCode
    );
  }

  handleAttributeButtonClick(type) {
    const {
      serviceShubetsuCategoryCode,
      serviceShubetsuCode,
    } = this.props.jigyoshoStatus;
    this.props.onAttributeButtonClick(
      this.props.id,
      type,
      serviceShubetsuCategoryCode,
      serviceShubetsuCode
    );
  }

  dataIsLoading() {
    return this.props.jigyoshoStatus.dataIsLoading;
  }

  render() {
    const { jigyoshoStatus, masterData } = this.props;
    const serviceShubetsu = masterData.serviceShubetsu.find(
      (it) => it.code == jigyoshoStatus.serviceShubetsuCode
    );
    return (
      <Boundary>
        <SideMargin>
          {this.renderHead()}
          {this.renderNavigation()}
          {this.dataIsLoading() || !serviceShubetsu ? (
            <CenteredCircularProgress />
          ) : (
            <Fragment>
              <Mount
                if={serviceShubetsu.isSearchableInFrontend}
                then={() => {
                  return (
                    <GridRow>
                      {this.renderPhotoStatus()}
                      {this.renderFlierStatus()}
                    </GridRow>
                  );
                }}
              />
              {this.renderAttributesStatus()}
            </Fragment>
          )}
          {this.renderEditAgents()}
        </SideMargin>
      </Boundary>
    );
  }

  renderHead() {
    const { jigyoshoStatus } = this.props;
    return (
      <BackOfficeHead>
        <Mount if={jigyoshoStatus.privateFlg}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <LockIcon style={{ color: "lightcoral", width: "16px" }} />
            <span
              style={{
                fontWeight: "bold",
                color: "lightcoral",
                fontSize: "0.85rem",
              }}
            >
              非公開設定中
            </span>
          </div>
        </Mount>
        {jigyoshoStatus.jigyoshomei ? jigyoshoStatus.jigyoshomei : "..."}
      </BackOfficeHead>
    );
  }

  renderNavigation() {
    const { serviceShubetsuCode } = this.props.jigyoshoStatus;
    return (
      <BackOfficeNavigation>
        <GridRow>
          <GridCell>
            <FlatPrimaryButton onClick={this.handleBackButtonClick}>
              <BackIcon />
              戻る
            </FlatPrimaryButton>
          </GridCell>
          <Mount
            if={
              ![
                ServiceShubetsuType.地域包括支援センター,
                ServiceShubetsuType.社会福祉協議会,
                ServiceShubetsuType.その他,
              ]
                .map((it) => it.code)
                .includes(serviceShubetsuCode)
            }
          >
            <GridCell>
              <Right>
                <FlatPrimaryButton onClick={this.handlePreviewButtonClick}>
                  <PageviewIcon />
                  表示を確認する
                </FlatPrimaryButton>
              </Right>
            </GridCell>
          </Mount>
        </GridRow>
      </BackOfficeNavigation>
    );
  }

  renderPhotoStatus() {
    const { photo } = this.props.jigyoshoStatus;
    if (!photo) return null;
    return (
      <GridCell>
        <JigyoshoStatusButton
          status={photo.status}
          onClick={this.handlePhotoButtonClick}
        >
          <PhotoIcon className={css(styles.icon)} />
          写真
        </JigyoshoStatusButton>
      </GridCell>
    );
  }

  renderFlierStatus() {
    const { flier } = this.props.jigyoshoStatus;
    if (!flier) return null;
    return (
      <GridCell>
        <JigyoshoStatusButton
          status={flier.status}
          onClick={this.handleFlierButtonClick}
        >
          <FileIcon className={css(styles.icon)} />
          チラシ（PDF）
        </JigyoshoStatusButton>
      </GridCell>
    );
  }

  renderAttributesStatus() {
    const { attributes, serviceShubetsuCode } = this.props.jigyoshoStatus;
    const sortedAttributes = jigyoshoAttributeTypeValues()
      .filter((type) => attributes[type.name])
      .map((type) => ({ ...attributes[type.name], type: type.name }));
    const attributesTable = new SplitArrayTable(4)
      .make(sortedAttributes)
      .fill()
      .getTable();
    return attributesTable.map((row, index) => (
      <GridRow key={index}>
        {row.map((attribute, index) => (
          <GridCell key={index}>
            <Mount
              if={attribute}
              then={() => {
                const jigyoshoAttributeType = jigyoshoAttributeTypeValueOf(
                  attribute.type,
                  serviceShubetsuCode
                );
                const jigyoshoAttributeLabel = jigyoshoAttributeLabelOf(
                  jigyoshoAttributeType,
                  serviceShubetsuCode
                );
                return (
                  <JigyoshoStatusButton
                    status={attribute.status}
                    onClick={() =>
                      this.handleAttributeButtonClick(attribute.type)
                    }
                  >
                    {jigyoshoAttributeLabel}
                  </JigyoshoStatusButton>
                );
              }}
            />
          </GridCell>
        ))}
      </GridRow>
    ));
  }

  renderEditAgents() {
    const { editAgents } = this.props.jigyoshoStatus;
    if (editAgents == null || editAgents < 1) return null;
    return (
      <FixedTable className={css(styles.editAgentsTabele)}>
        <TableHead>
          <TableRow className={css(styles.editAgentsTabeleRow)}>
            <TableCell
              className={css(styles.editAgentsTabeleHeadCell)}
              style={{ width: "60%" }}
            >
              更新事業所
            </TableCell>
            <TableCell className={css(styles.editAgentsTabeleHeadCell)}>
              最新更新日時
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {editAgents.map((editAgent, index) => (
            <TableRow key={index} className={css(styles.editAgentsTabeleRow)}>
              <TableCell className={css(styles.editAgentsTabeleBodyCell)}>
                {editAgent.jigyoshoName}
              </TableCell>
              <TableCell className={css(styles.editAgentsTabeleBodyCell)}>
                {editAgent.editDate}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </FixedTable>
    );
  }
}

JigyoshoStatusComponent.propTypes = {
  // state
  id: PropTypes.number.isRequired,
  jigyoshoStatus: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  // action
  onAttributeButtonClick: PropTypes.func.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
  onDidMount: PropTypes.func.isRequired,
  onFlierButtonClick: PropTypes.func.isRequired,
  onPhotoButtonClick: PropTypes.func.isRequired,
  onPreviewButtonClick: PropTypes.func.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_JIGYOSHO)(
  connect(mapStateToProps, mapDispatchToProps)(JigyoshoStatusComponent)
);

function mapStateToProps(state) {
  return {
    jigyoshoStatus: state.jigyoshoStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onDidMount: (id) => {
      dispatch(jigyoshoStatusActions.reset(id));
    },
  };
}
