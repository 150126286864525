import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import GridRow from "../../../../../app/ui/grid/GridRow";
import GridCell from "../../../../../app/ui/grid/GridCell";
import { enterKeyHandler } from "../../../../../app/ui/form";
import InputTypeCheckbox from "../../../../../app/ui/form/InputTypeCheckbox";
import {
  MASTER_DATA_BUNDLE_FOR_HOKENGAI,
  withMasterData,
} from "../../../../../state/master-data";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import TimePeriod from "../../../../../app/ui/form/TimePeriod";
import DummyLabel from "../../../../../app/ui/form/DummyLabel";
import { IconButton } from "@material-ui/core";
import FindIcon from "@material-ui/icons/FindReplace";
import LockIcon from "@material-ui/icons/Lock";
import { zipcodeToAddress } from "../../../../../state/zipcode-search/actions";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";
import RatioBlock from "../../../../../app/ui/layout/RatioBlock";
import Mount from "../../../../../app/ui/layout/Mount";
import Right from "../../../../../app/ui/layout/Right";
import StaticLabel from "../../../../../app/ui/form/StaticLabel";
import Textarea from "../../../../../app/ui/form/Textarea";

class BusinessForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleEnterKeyDownOnYubimbango = this.handleEnterKeyDownOnYubimbango.bind(
      this
    );
  }

  handleEnterKeyDownOnYubimbango() {
    const { actions, bindingProps } = this.props;
    const { form, onChange: changeForm } = bindingProps;
    const zipcode = form.getByName("zipCode");
    actions.zipcodeToAddress(zipcode, (address) => {
      changeForm("prefName", address.pref);
      changeForm("cityName", address.address);
    });
  }

  isJushoOnly() {
    const { bindingProps } = this.props;
    return bindingProps.form.jushoHokengaiOnly || false;
  }

  render() {
    const { serviceShubetsuCode } = this.props;
    return (
      <Fragment>
        <Mount if={!this.isJushoOnly()}>
          {this.renderPublic()}
          {this.renderTempo()}
        </Mount>
        {this.renderJusho()}
        <Mount if={!this.isJushoOnly()}>
          {this.renderHP()}
          {this.renderToiawase()}
          <Mount
            if={
              ![ServiceShubetsuType.活動_通いの場]
                .map((it) => it.code)
                .includes(serviceShubetsuCode)
            }
          >
            {this.renderEigyojikan()}
          </Mount>
          {this.renderOther()}
        </Mount>
      </Fragment>
    );
  }

  renderPublic() {
    const { bindingProps, serviceShubetsuCode } = this.props;
    if (
      ![ServiceShubetsuType.活動_通いの場]
        .map((it) => it.code)
        .includes(serviceShubetsuCode)
    )
      return;
    return (
      <div>
        <InputTypeCheckbox
          labelText={
            <div style={{ display: "flex", alignItems: "center" }}>
              <LockIcon style={{ color: "lightcoral", width: "16px" }} />
              <span
                style={{
                  color: "lightcoral",
                  fontWeight: "bold",
                  fontSize: "0.85rem",
                }}
              >
                全ての情報を公開しない
              </span>
            </div>
          }
          name="privateFlg"
          {...bindingProps}
        />
      </div>
    );
  }

  renderTempo() {
    const { bindingProps, masterData, serviceShubetsuCode } = this.props;
    return (
      <FormGrouping title="店舗・事業所・団体について">
        <HorizontalLabelLayout labelText="運営法人名">
          <InputTypeText name="corporationName" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="店舗・事業所・団体名" required>
          <InputTypeText name="name" {...bindingProps} />
        </HorizontalLabelLayout>
        <Mount
          if={[ServiceShubetsuType.活動_通いの場]
            .map((it) => it.code)
            .includes(serviceShubetsuCode)}
        >
          <HorizontalLabelLayout labelText="団体種別" required>
            <OptionsRadio
              name="dantaishubetsu"
              split={3}
              options={masterData.dantaishubetsuCode}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
        </Mount>
      </FormGrouping>
    );
  }

  renderJusho() {
    const { bindingProps, serviceShubetsuCode } = this.props;
    const { user } = this.props.authentication;
    return (
      <FormGrouping
        title={
          [ServiceShubetsuType.活動_通いの場]
            .map((it) => it.code)
            .includes(serviceShubetsuCode)
            ? "代表者住所（店舗住所）"
            : "住所"
        }
      >
        　
        <Mount if={user.canEditJushoOnly()}>
          <HorizontalLabelLayout labelText="住所のみ変更">
            <InputTypeCheckbox name="jushoHokengaiOnly" {...bindingProps} />
          </HorizontalLabelLayout>
        </Mount>
        <HorizontalLabelLayout labelText="〒 郵便番号" required>
          <GridRow>
            <GridCell>
              <InputTypeText
                name="zipCode"
                hintText="000-0000"
                {...bindingProps}
                onKeyDown={enterKeyHandler(this.handleEnterKeyDownOnYubimbango)}
              />
            </GridCell>
            <GridCell grow={2}>
              <IconButton onClick={this.handleEnterKeyDownOnYubimbango}>
                <FindIcon />
              </IconButton>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="都道府県" required>
          <DummyLabel name="prefName" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="市区町村" required>
          <ShortInputWidth>
            <InputTypeText name="cityName" {...bindingProps} />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="番地" required>
          <ShortInputWidth>
            <InputTypeText name="address" hintText="0番地" {...bindingProps} />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="建物以下">
          <InputTypeText name="building" {...bindingProps} />
        </HorizontalLabelLayout>
        <Mount if={!this.isJushoOnly()}>
          <HorizontalLabelLayout labelText="非公開設定">
            <InputTypeCheckbox
              name="jushoHikokai"
              labelText="住所を公開しない"
              {...bindingProps}
            />
          </HorizontalLabelLayout>
        </Mount>
      </FormGrouping>
    );
  }

  renderHP() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="ホームページ">
        <HorizontalLabelLayout labelText="URL">
          <InputTypeText
            name="homepageUrl"
            hintText="https://"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderToiawase() {
    const { bindingProps, serviceShubetsuCode } = this.props;
    const kayoinobaFlg = [ServiceShubetsuType.活動_通いの場]
      .map((it) => it.code)
      .includes(serviceShubetsuCode);
    return (
      <FormGrouping
        title={
          [ServiceShubetsuType.活動_通いの場]
            .map((it) => it.code)
            .includes(serviceShubetsuCode)
            ? "連絡先"
            : "問い合わせ・予約"
        }
      >
        <Mount if={kayoinobaFlg}>
          <HorizontalLabelLayout labelText="代表者名">
            <InputTypeText name="daihyoshaName" {...bindingProps} />
          </HorizontalLabelLayout>
        </Mount>
        <HorizontalLabelLayout
          labelText={kayoinobaFlg ? "☎ 代表者電話番号" : "☎ 電話番号"}
          required
        >
          <GridRow>
            <GridCell>
              <Mount if={kayoinobaFlg}>
                <InputTypeText
                  name="tel"
                  hintText="00-0000-0000"
                  {...bindingProps}
                />
              </Mount>
              <Mount if={!kayoinobaFlg}>
                <InputTypeText
                  name="tel"
                  hintText="00-0000-0000"
                  {...bindingProps}
                />
              </Mount>
            </GridCell>
            <GridCell grow={2}>
              <div>
                <RatioBlock divide={12}>
                  <Right>（ </Right>
                </RatioBlock>
                <RatioBlock grow={6} divide={12}>
                  <InputTypeText
                    name="telToiawasesaki"
                    hintText="例）ミルモネット問い合わせ係"
                    {...bindingProps}
                  />
                </RatioBlock>
                <RatioBlock divide={12}>）</RatioBlock>
              </div>
            </GridCell>
          </GridRow>
          <StaticLabel>
            ※
            お問合せ先が店舗・事業所・団体と異なる場合お問合せ先をご記入ください
            <br />
          </StaticLabel>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout
          labelText={kayoinobaFlg ? "代表者FAX" : "FAX"}
          recommended
        >
          <ShortInputWidth>
            <InputTypeText
              name="fax"
              hintText="00-0000-0000"
              {...bindingProps}
            />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="非公開設定">
          <InputTypeCheckbox
            name="denwabangoFaxHikokai"
            labelText="電話番号・FAXを公開しない"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="✉ E-mail">
          <InputTypeText
            name="email"
            hintText="xxxx@xxxx.xxxx"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="Webフォーム">
          <InputTypeText
            name="contactUrl"
            hintText="https://"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderEigyojikan() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="営業時間">
        <HorizontalLabelLayout labelText={this.eigyojikanLabel()} required>
          <ShortInputWidth>
            <TimePeriod
              name="businessAt"
              separator="."
              {...bindingProps}
              disabled={bindingProps.form.allDayFlg}
            />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="24時間対応">
          <InputTypeCheckbox
            name="allDayFlg"
            labelText="はい"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText={this.eigyobiLabel()} required>
          <OptionsCheckbox
            name="eigyobi"
            split={6}
            options={masterData.eigyobiOptions}
            disabled={bindingProps.form.allYearFlg}
            {...bindingProps}
          />
          <StaticLabel>
            ※ 隔週の場合も曜日にチェックを入れください
            <br />
          </StaticLabel>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="年中無休">
          <InputTypeCheckbox
            name="allYearFlg"
            labelText="はい"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout
          labelText={this.eigyobiBikoLabel()}
          recommended={this.eigyobiBikoRecommended()}
        >
          <InputTypeText
            name="closeBusinessComment"
            hintText="例）毎月第２水曜日に開催、祝日年末年始はお休み"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderOther() {
    const { bindingProps } = this.props;
    const { user } = this.props.authentication;
    return (
      <FormGrouping title="その他">
        <HorizontalLabelLayout labelText="特記事項">
          <Textarea name="tokkijiko" characterLimit={400} {...bindingProps} />
        </HorizontalLabelLayout>
        <Mount if={user.canEditApplicationFormLink()}>
          <HorizontalLabelLayout labelText="申込みフォームURL">
            <InputTypeText name="applicationFormLink" {...bindingProps} />
          </HorizontalLabelLayout>
        </Mount>
      </FormGrouping>
    );
  }

  eigyojikanLabel() {
    return this.eigyoLabel() + "時間";
  }
  eigyobiLabel() {
    return this.eigyoLabel() + "日";
  }
  eigyobiBikoLabel() {
    return this.eigyobiLabel() + "備考";
  }
  eigyoLabel() {
    const { serviceShubetsuCode } = this.props;
    return [ServiceShubetsuType.活動_通いの場]
      .map((it) => it.code)
      .includes(serviceShubetsuCode)
      ? "営業・開催"
      : "営業";
  }
  eigyobiBikoRecommended() {
    const { serviceShubetsuCode } = this.props;
    return [ServiceShubetsuType.活動_通いの場]
      .map((it) => it.code)
      .includes(serviceShubetsuCode);
  }
}

BusinessForm.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
  authentication: PropTypes.object.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOKENGAI)(
  connect(mapStateToProps, mapDispatchToProps)(BusinessForm)
);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      zipcodeToAddress: (zipcode, callback) =>
        dispatch(zipcodeToAddress(zipcode, callback)),
    },
  };
}
