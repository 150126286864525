import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import InputTypeDate from "../../../../../app/ui/form/InputTypeDate";
import GridRow from "../../../../../app/ui/grid/GridRow";
import GridCell from "../../../../../app/ui/grid/GridCell";
import { IconButton } from "@material-ui/core";
import FindIcon from "@material-ui/icons/FindReplace";
import { enterKeyHandler } from "../../../../../app/ui/form";
import { zipcodeToAddress } from "../../../../../state/zipcode-search/actions";
import Textarea from "../../../../../app/ui/form/Textarea";
import Mount from "../../../../../app/ui/layout/Mount";
import InputTypeCheckbox from "../../../../../app/ui/form/InputTypeCheckbox";
import {
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO,
  withMasterData,
} from "../../../../../state/master-data";
import DummyLabel from "../../../../../app/ui/form/DummyLabel";
import { serviceShubetsuTypeValueOf } from "../../../../../app/domain/jigyosho/ServiceShubetsuType";

class JigyoshoJohoForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleEnterKeyDownOnYubimbango = this.handleEnterKeyDownOnYubimbango.bind(
      this
    );
  }

  handleEnterKeyDownOnYubimbango() {
    const { actions, bindingProps } = this.props;
    const { form, onChange: changeForm } = bindingProps;
    const zipcode = form.getByName("yubimbango");
    actions.zipcodeToAddress(zipcode, (address) => {
      changeForm("todofuken", address.pref);
      changeForm("shikuchoson", address.address);
    });
  }

  isJushoOnly() {
    const { bindingProps } = this.props;
    return bindingProps.form.jushoOnly || false;
  }

  render() {
    return (
      <Fragment>
        <Mount if={!this.isJushoOnly()}>
          {this.renderJigyoshoJoho()}
          {this.renderDenwabangoFax()}
        </Mount>
        {this.renderJusho()}
        <Mount if={!this.isJushoOnly()}>
          {this.renderUnei()}
          {this.renderOther()}
        </Mount>
      </Fragment>
    );
  }

  renderJigyoshoJoho() {
    const { bindingProps } = this.props;
    const shubetsuCode = bindingProps.form.serviceShubetsuCode;
    return (
      <FormGrouping title="事業所情報">
        <HorizontalLabelLayout labelText="事業所名" required>
          <InputTypeText name="jigyoshomei" {...bindingProps} />
        </HorizontalLabelLayout>
        <Mount
          if={serviceShubetsuTypeValueOf(shubetsuCode).hissuJigyoshobango}
          then={() => (
            <HorizontalLabelLayout labelText="事業所番号" required>
              <ShortInputWidth>
                <InputTypeText
                  name="jigyoshobango"
                  hintText="0000000000"
                  {...bindingProps}
                />
              </ShortInputWidth>
            </HorizontalLabelLayout>
          )}
        />
        <HorizontalLabelLayout labelText="事業開始日" required>
          <ShortInputWidth>
            <InputTypeDate name="jigyokaishibi" {...bindingProps} />
          </ShortInputWidth>
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderDenwabangoFax() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="電話番号・FAX">
        <HorizontalLabelLayout labelText="☎ 電話番号" required>
          <ShortInputWidth>
            <InputTypeText
              name="denwabango"
              hintText="00-0000-0000"
              {...bindingProps}
            />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="FAX" recommended>
          <ShortInputWidth>
            <InputTypeText
              name="fax"
              hintText="00-0000-0000"
              {...bindingProps}
            />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="非公開設定">
          <InputTypeCheckbox
            name="denwabangoFaxHikokai"
            labelText="電話番号・FAXを公開しない"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderJusho() {
    const { bindingProps } = this.props;
    const { user } = this.props.authentication;
    return (
      <FormGrouping title="住所">
        <Mount if={user.canEditJushoOnly()}>
          <HorizontalLabelLayout labelText="住所のみ変更">
            <InputTypeCheckbox name="jushoOnly" {...bindingProps} />
          </HorizontalLabelLayout>
        </Mount>
        <HorizontalLabelLayout labelText="〒 郵便番号" required>
          <GridRow>
            <GridCell>
              <InputTypeText
                name="yubimbango"
                hintText="000-0000"
                {...bindingProps}
                onKeyDown={enterKeyHandler(this.handleEnterKeyDownOnYubimbango)}
              />
            </GridCell>
            <GridCell grow={2}>
              <IconButton onClick={this.handleEnterKeyDownOnYubimbango}>
                <FindIcon />
              </IconButton>
            </GridCell>
          </GridRow>
          <ShortInputWidth></ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="都道府県" required>
          <DummyLabel name="todofuken" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="市区町村" required>
          <ShortInputWidth>
            <InputTypeText name="shikuchoson" {...bindingProps} />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="番地" required>
          <ShortInputWidth>
            <InputTypeText name="banchi" hintText="0番地" {...bindingProps} />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="建物名">
          <InputTypeText name="tatemonomei" {...bindingProps} />
        </HorizontalLabelLayout>
        <Mount if={!this.isJushoOnly()}>
          <HorizontalLabelLayout labelText="非公開設定">
            <InputTypeCheckbox
              name="jushoHikokai"
              labelText="住所を公開しない"
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="交通手段">
            <Textarea name="kotsushudan" {...bindingProps} />
          </HorizontalLabelLayout>
        </Mount>
      </FormGrouping>
    );
  }

  renderUnei() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="運営情報">
        <HorizontalLabelLayout labelText="管理者名" required>
          <ShortInputWidth>
            <InputTypeText name="kanrishamei" {...bindingProps} />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="運営法人" required>
          <InputTypeText name="uneihojin" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="HPアドレス" recommended>
          <InputTypeText
            name="hpAddress"
            hintText="http://"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderOther() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="その他">
        <HorizontalLabelLayout labelText="特記事項">
          <Textarea name="tokkijiko" characterLimit={400} {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

JigyoshoJohoForm.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  authentication: PropTypes.object.isRequired,
};

export default withMasterData(
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO
)(connect(mapStateToProps, mapDispatchToProps)(JigyoshoJohoForm));

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      zipcodeToAddress: (zipcode, callback) =>
        dispatch(zipcodeToAddress(zipcode, callback)),
    },
  };
}
