import React, { Fragment } from "react";
import PropTypes from "prop-types";
import StaticLabel from "../../../../app/ui/form/StaticLabel";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import FormGrouping from "../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../app/ui/form/HorizontalLabelLayout";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import { connect } from "react-redux";
import { Card } from "@material-ui/core";
import Right from "../../../../app/ui/layout/Right";
import JobOfferStatusChip from "../ui/JobOfferStatusChip";
import JobOfferEditAlertDialog from "../ui/JobOfferEditAlertDialog";

class JobOfferBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editAlertDialogIsOpen: false,
    };

    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.handleEditAlertDialogCloseButtonClick = this.handleEditAlertDialogCloseButtonClick.bind(
      this
    );
    this.handleEditAlertDialogSubmitButtonClick = this.handleEditAlertDialogSubmitButtonClick.bind(
      this
    );
  }

  jobOfferId() {
    return this.props.jobOffer.detail.id;
  }

  handleEditButtonClick() {
    const { detail } = this.props.jobOffer;
    if (detail.approvalRequested || detail.approved) {
      this.setState({ editAlertDialogIsOpen: true });
    } else {
      this.props.gotoJobOfferEditPage(this.jobOfferId());
    }
  }

  handleEditAlertDialogCloseButtonClick() {
    this.setState({ editAlertDialogIsOpen: false });
  }

  handleEditAlertDialogSubmitButtonClick() {
    this.setState({ editAlertDialogIsOpen: false });
    this.props.gotoJobOfferEditPage(this.jobOfferId());
  }

  render() {
    return (
      <Fragment>
        {this.renderJobOffer()}
        <JobOfferEditAlertDialog
          open={this.state.editAlertDialogIsOpen}
          onClose={this.handleEditAlertDialogCloseButtonClick}
          onSubmit={this.handleEditAlertDialogSubmitButtonClick}
        />
      </Fragment>
    );
  }

  renderJobOffer() {
    const { detail } = this.props.jobOffer;

    const title = (
      <Fragment>
        求人情報{" "}
        <span style={{ paddingLeft: "10px" }}>
          <JobOfferStatusChip status={detail.status} />
        </span>
        <Right>
          <FlatPrimaryButton onClick={this.handleEditButtonClick}>
            編集する
          </FlatPrimaryButton>
        </Right>
      </Fragment>
    );

    return (
      <Card>
        <SideMargin top bottom>
          <FormGrouping title={title}>
            <HorizontalLabelLayout labelText="職種" required>
              <StaticLabel>{detail.jobType}</StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="職種の説明" required>
              <StaticLabel>{detail.description}</StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="契約形態" required>
              <StaticLabel>{detail.jobContract}</StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="仕事内容" required>
              <StaticLabel preLabel>{detail.jobDescription}</StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="応募要件" required>
              <StaticLabel preLabel>
                {detail.applicationQualification}
              </StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="応募要件タグ" required>
              <StaticLabel>
                {detail.applicationQualificationTags.join("、")}
              </StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="歓迎要件" required>
              <StaticLabel preLabel>{detail.preferredRequirements}</StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="給与" required>
              <StaticLabel preLabel>{detail.salary}</StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="勤務時間" required>
              <StaticLabel preLabel>{detail.workingHours}</StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="休日・休暇" required>
              <StaticLabel preLabel>{detail.holiday}</StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="通勤手段" required>
              <StaticLabel>{detail.commuteOptions.join("、")}</StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="福利厚生" required>
              <StaticLabel>{detail.treatments.join("、")}</StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout
              labelText="待遇・福利厚生（その他）"
              required
            >
              <StaticLabel preLabel>{detail.benefits}</StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="勤務地">
              <StaticLabel preLabel>{detail.workLocation}</StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="募集背景">
              <StaticLabel preLabel>{detail.recruitmentBackground}</StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="選考プロセス">
              <StaticLabel preLabel>{detail.selectionProcess}</StaticLabel>
            </HorizontalLabelLayout>
          </FormGrouping>

          <FormGrouping title="アピールポイント">
            <HorizontalLabelLayout labelText="タイトル" required>
              <StaticLabel preLabel>{detail.promotionTitle}</StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="本文">
              <StaticLabel preLabel>{detail.promotionDetail}</StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="画像">
              <img src={detail.promotionImageUrl} style={{ maxWidth: "90%" }} />
            </HorizontalLabelLayout>
          </FormGrouping>

          <FormGrouping title="公開設定">
            <HorizontalLabelLayout labelText="掲載期間" required>
              <StaticLabel>
                {detail.publishStartDate} 〜 {detail.publishEndDate}
              </StaticLabel>
            </HorizontalLabelLayout>
          </FormGrouping>
        </SideMargin>
      </Card>
    );
  }
}

JobOfferBlock.propTypes = {
  jobOffer: PropTypes.object.isRequired,
  gotoJobOfferEditPage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    jobOffer: state.jobOffer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    gotoJobOfferEditPage: (id) => {
      dispatch(push(Pathname.backoffice_job_offer_id_edit.replace(/:id/, id)));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobOfferBlock);
