import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import Textarea from "../../../../../app/ui/form/Textarea";

class ServiceKaishiBusinessProvideOther extends React.Component {
  render() {
    const { bindingProps } = this.props;
    return (
      <Fragment>
        <FormGrouping title="ご利用開始について">
          <HorizontalLabelLayout labelText="ご利用開始までの流れ" recommended>
            <Textarea name="serviceFlow" {...bindingProps} />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="ご利用可能対象者" required>
            <Textarea
              name="serviceTarget"
              {...bindingProps}
              hintText="例）60歳以上の方、町内会在住者、○○会員など"
            />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="提供・対象地域" required>
            <Textarea
              name="serviceArea"
              {...bindingProps}
              hintText="例）○○町内会、○○地区など"
            />
          </HorizontalLabelLayout>
          {this.props.children}
        </FormGrouping>
      </Fragment>
    );
  }
}

ServiceKaishiBusinessProvideOther.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default ServiceKaishiBusinessProvideOther;
