import ActionType from "./reducer/ActionType";
import ApiPath from "../../app/fundamental/request/ApiPath";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
  withCompletionMessage,
} from "../../app/fundamental/request";
import { openMessage } from "../notification-message/actions";

function reset() {
  return {
    type: ActionType.RESET,
  };
}

export function requestBlankForm(groupId) {
  return (dispatch) => {
    dispatch(reset());
    dispatch({
      type: ActionType.REQUEST,
    });
    getBlankForm(dispatch, groupId);
  };
}

export function requestAgentCollectionBlankForm(groupId) {
  return (dispatch) => {
    dispatch(reset());
    dispatch({
      type: ActionType.REQUEST,
    });
    getAgentCollectionBlankForm(dispatch, groupId);
  };
}

export function requestAttribute(id, type, serviceShubetsuCode) {
  return (dispatch) => {
    dispatch(reset());
    dispatch({
      type: ActionType.REQUEST,
    });
    getAttribute(dispatch, id, type, serviceShubetsuCode);
  };
}

export function changeForm(name, value, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.CHANGE_FORM,
      payload: {
        name,
        value,
      },
    });
    setTimeout(callback);
  };
}

export function submitToAdd(form, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    postToAdd(dispatch, form, callback);
  };
}

export function submitToCollect(form, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    postToCollect(dispatch, form, callback);
  };
}

export function submitToEditAttribute(id, type, form, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    postToEditAttribute(dispatch, id, type, form, callback);
  };
}

export function submitToRemove(id, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    del(dispatch, id, callback);
  };
}

export function submitToJoin(id, groupId, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    const form = { groupId };
    postToJoin(dispatch, id, form, callback);
  };
}

export function submitToRemoveOpenData(id, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    delOpenData(dispatch, id, callback);
  };
}

function getBlankForm(dispatch, groupId) {
  const searchParams = new URLSearchParams();
  if (groupId != null) searchParams.set("groupId", groupId);
  const url = `${ApiPath.api_jigyosho_blankform}?${searchParams.toString()}`;
  getForm(dispatch, url, "blank");
}

function getAgentCollectionBlankForm(dispatch) {
  const url = ApiPath.api_agent_collection_blankform;
  getForm(dispatch, url, "agent");
}

function getAttribute(dispatch, id, type, serviceShubetsuCode) {
  const url = ApiPath.api_jigyosho_id_attributes_type
    .replace(/:id/, id)
    .replace(/:type/, type);
  getForm(dispatch, url, type, serviceShubetsuCode);
}

function getForm(dispatch, url, type, serviceShubetsuCode = "") {
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.REQUEST_FINISHED,
      payload: {
        form: json,
        type,
        serviceShubetsuCode,
      },
    });
  };
  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}

function postToAdd(dispatch, form, callback) {
  const url = ApiPath.api_jigyosho;
  post(dispatch, url, form, callback, "事業所情報を新規に追加しました。");
}

function postToCollect(dispatch, form, callback) {
  const url = ApiPath.api_agent_collection;
  post(dispatch, url, form, callback);
}

function postToEditAttribute(dispatch, id, type, form, callback) {
  const url = ApiPath.api_jigyosho_id_attributes_type
    .replace(/:id/, id)
    .replace(/:type/, type);
  post(dispatch, url, form, callback, "事業所情報を更新しました。");
}

function postToJoin(dispatch, id, form, callback) {
  const url = ApiPath.api_jigyosho_id_join.replace(/:id/, id);

  const onSuccess = (json) => {
    dispatch({
      type: ActionType.SUBMIT_FINISHED,
    });
    setTimeout(() => callback(json));
  };
  const responseHandler = defaultResponseHandler(dispatch, onSuccess);
  responseHandler.onError = () => {
    dispatch({
      type: ActionType.SUBMIT_FAILED,
    });
    dispatch(
      openMessage(
        "メンテナンス中のため、事業所を追加出来ませんでした。しばらく時間をおいてお試しください。"
      )
    );
    callbackOnFailure();
  };
  ApiRequest.post(url, form).request(
    withCompletionMessage(
      dispatch,
      "オープンデータから事業所情報を追加しました。"
    )(responseHandler),
    defaultUnauthorizedHander(dispatch)
  );
}

function post(dispatch, url, form, callback = () => {}, message) {
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.SUBMIT_FINISHED,
    });
    setTimeout(() => callback(json));
  };
  const onFailure = () => {
    dispatch({
      type: ActionType.SUBMIT_FAILED,
    });
  };
  ApiRequest.post(url, form).request(
    withCompletionMessage(
      dispatch,
      message
    )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
    defaultUnauthorizedHander(dispatch)
  );
}

function del(dispatch, id, callback = () => {}) {
  const url = ApiPath.api_jigyosho_id.replace(/:id/, id);
  const onSuccess = () => {
    dispatch({
      type: ActionType.SUBMIT_FINISHED,
    });
    setTimeout(callback);
  };
  const onFailure = () => {
    dispatch({
      type: ActionType.SUBMIT_FAILED,
    });
  };
  ApiRequest.delete(url).request(
    withCompletionMessage(
      dispatch,
      "事業所情報を削除しました。"
    )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
    defaultUnauthorizedHander(dispatch)
  );
}

function delOpenData(dispatch, id, callback = () => {}) {
  const url = ApiPath.api_opendata_id.replace(/:id/, id);
  const onSuccess = () => {
    dispatch({
      type: ActionType.SUBMIT_FINISHED,
    });
    setTimeout(callback);
  };
  const onFailure = () => {
    dispatch({
      type: ActionType.SUBMIT_FAILED,
    });
  };
  ApiRequest.delete(url).request(
    withCompletionMessage(
      dispatch,
      "事業所情報を削除しました。"
    )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
    defaultUnauthorizedHander(dispatch)
  );
}
